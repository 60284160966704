import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { links } from '@/common/utils/links';

import { Settings } from './layouts/settings';

const ApiData = lazy(() => import('./pages/apiData'));
const Company = lazy(() => import('./pages/company'));
const Modules = lazy(() => import('./pages/modules'));
const Drivers = lazy(
  () => import('../../../apps/nexpro/modules/registrations/pages/drivers'),
);
const Groups = lazy(
  () => import('../../../apps/nexpro/modules/registrations/pages/groups'),
);
const Vehicles = lazy(
  () => import('../../../apps/nexpro/modules/registrations/pages/vehicles'),
);
const User = lazy(() => import('./pages/user'));
const SettingsOptions = lazy(() => import('./pages/settingsOptions'));

export const SettingsRoutes: RouteObject[] = [
  {
    element: <Settings />,
    children: [
      {
        path: links.settings.company,
        element: <Company />,
      },
      {
        path: links.settings.modules,
        element: <Modules />,
      },
      {
        path: links.settings.users,
        element: <User />,
      },
      {
        path: links.settings.apiData,
        element: <ApiData />,
      },
      {
        path: links.settings.drivers,
        element: <Drivers />,
      },
      {
        path: links.settings.groups,
        element: <Groups />,
      },
      {
        path: links.settings.vehicles,
        element: <Vehicles />,
      },
      {
        path: links.settings.settingsOptions,
        element: <SettingsOptions />,
      },
    ],
  },
];
