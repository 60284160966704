import { ENV } from '@/config/env';

export const links = {
  clients: {
    list: '/clients',
  },
  settings: {
    company: '/settings/company',
    users: '/settings/users',
    modules: '/settings/modules',
    apiData: '/settings/api-data',
    drivers: '/settings/drivers',
    vehicles: '/settings/vehicles',
    groups: '/settings/groups',
    settingsOptions: `/settings`,
  },
  userProfile: {
    profile: `/user-profile/profile`,
    password: `/user-profile/password`,
    userProfileOptions: `/user-profile`,
  },
  login: {
    main: '/',
    appSelection: '/app-selection',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password',
    activateAccount: '/activate-account',
  },
  nexpro: {
    main: '/',
    registrations: {
      drivers: '/registrations/drivers',
      vehicles: '/registrations/vehicles',
      groups: '/registrations/groups',
      maintenance: '/registrations/maintenance',
    },
    monitoring: '/monitoring',
    dashboard: '/dashboard-2',
    journey: '/monitoring/journey/events',
    performance: {
      vehicles: '/performance/vehicles',
      drivers: '/performance/drivers',
    },
    checkIn: '/check-in',
    travel: '/travel',
    geoFences: '/geofences',
    controlRoom: '/control-room',
    reports: {
      heatmap: '/reports/heatmap',
      activities: '/reports/activities',
      fleets: '/reports/fleets',
      vehicleComparison: '/reports/fleets/vehicle-comparison',
    },
  },
  privacyPolicy: '/privacy-policy',
  termsOfUse: ENV.VITE_TERMS_OF_USE_URL,
};
